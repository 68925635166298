import gql from 'graphql-tag';
import { answerFragment, surveyFragment } from './fragments';

export const createAnswerMutation = gql`
  mutation createAnswer($answer: AnswerInput!) {
    createAnswer(input: { answer: $answer }) {
      answer {
        ...answerFragment
      }
    }
  }
  ${answerFragment}
`;

export const createSurveyMutation = gql`
  mutation createSurvey($survey: SurveyInput!) {
    createSurvey(input: { survey: $survey }) {
      survey {
        ...surveyFragment
      }
    }
  }
  ${surveyFragment}
`;

export const updateSurveyMutation = gql`
  mutation updateSurvey($nodeId: ID!, $patch: SurveyPatch!) {
    updateSurvey(input: { nodeId: $nodeId, surveyPatch: $patch }) {
      survey {
        ...surveyFragment
      }
    }
  }
  ${surveyFragment}
`;

import React from 'react';
import styled from 'styled-components';
import { shape, string } from 'prop-types';

const PropTypes = {
  logo: shape({
    url: string.isRequired,
  }).isRequired,
};

const Navbar = styled.div`
  background: #ffc800;
  width: 100%;
  max-height: 60px;
  margin-bottom: 6.5rem;
`;

const Logo = styled.img`
  display: block;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  top: 26px;
`;

const Header = ({ logo }) => (
  <Navbar>
    <Logo src={logo.url} alt="logo" />
  </Navbar>
);

Header.propTypes = PropTypes;

export default Header;

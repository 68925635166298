import React from 'react';
import { arrayOf, shape, string } from 'prop-types';
import styled from 'styled-components';
import { mediaBreakpointDownSm } from 'styled-bootstrap-responsive-breakpoints';
import { Heading1 } from '../../Heading';
import Box from '../../Box';
import { contentWidth } from '../../../constants/styles';

const PropTypes = {
  whatNextTitle: string.isRequired,
  whatNextItems: arrayOf(
    shape({
      title: string.isRequired,
      subtitle: string.isRequired,
      buttonText: string.isRequired,
      link: string,
    }),
  ),
  reportLink: string.isRequired,
};

const DefaultProps = {
  whatNextItems: [],
};

const Container = styled.div`
  margin-top: 8.5rem;
`;

const TitleContainer = styled.div`
  max-width: ${contentWidth};
  margin: 0 auto;
`;

const Title = styled(Heading1)`
  text-align: left;
`;

const Boxes = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  margin-top: 1.5rem;
`;

const BoxContainer = styled.div`
  padding: 0.375rem 0.75rem;
  flex-basis: 47%;

  ${mediaBreakpointDownSm`
      flex-basis: 100%;
  `};
`;

const WhatNextSection = ({ whatNextTitle, whatNextItems, reportLink }) => (
  <Container>
    <TitleContainer>
      <Title>{whatNextTitle}</Title>
    </TitleContainer>
    <Boxes>
      {whatNextItems.map(({ key, link, ...item }) => (
        <BoxContainer key={key}>
          <Box {...item} link={key !== 'report' ? link : reportLink} />
        </BoxContainer>
      ))}
    </Boxes>
  </Container>
);

WhatNextSection.propTypes = PropTypes;
WhatNextSection.defaultProps = DefaultProps;

export default WhatNextSection;

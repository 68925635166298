import { compose } from 'recompose';
import SurveyErrorPage from '../../components/SurveyErrorPage';
import LoadingSpinner from '../../components/LoadingSpinner';
import ErrorPage from '../../components/ErrorPage';
import withPrismicMasterRef from '../../hoc/withPrismicMasterRef';
import withPrismicDocument from '../../hoc/withPrismicDocument';
import whenError from '../../hoc/whenError';
import whenLoading from '../../hoc/whenLoading';

export default compose(
  withPrismicMasterRef,
  withPrismicDocument('survey_error_page'),
  whenError(ErrorPage),
  whenLoading(LoadingSpinner),
)(SurveyErrorPage);

import React, { Fragment } from 'react';
import { string } from 'prop-types';
import styled from 'styled-components';
import { mediaBreakpointDownSm } from 'styled-bootstrap-responsive-breakpoints';
import copy from 'copy-to-clipboard';
import Box from '../../Box';
import { Heading1 } from '../../Heading';
import { contentWidth } from '../../../constants/styles';

const PropTypes = {
  linkSectionTitle: string.isRequired,
  surveyLink: string.isRequired,
  shareTitle: string.isRequired,
  shareButtonText: string.isRequired,
  resultsLink: string.isRequired,
  resultsLinkTitle: string.isRequired,
  resultsLinkSubtitle: string.isRequired,
  resultsLinkButtonText: string.isRequired,
};

const DefaultProps = {};

const Boxes = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
`;

const BoxContainer = styled.div`
  padding: 0.375rem;
  flex-basis: 47%;

  ${mediaBreakpointDownSm`
      flex-basis: 100%;
  `};
`;

const TitleSection = styled.div`
  max-width: ${contentWidth};
  margin: 6.5rem auto 1.5rem auto;
  text-align: left;
`;

const Title = styled(Heading1)``;

const LinksSection = ({
  linkSectionTitle,
  surveyLink,
  shareTitle,
  shareButtonText,
  resultsLink,
  resultsLinkTitle,
  resultsLinkSubtitle,
  resultsLinkButtonText,
}) => (
  <Fragment>
    <TitleSection>
      <Title>{linkSectionTitle}</Title>
    </TitleSection>
    <Boxes>
      <BoxContainer>
        <Box
          title={shareTitle}
          subtitle={surveyLink}
          buttonText={shareButtonText}
          link={null}
          onButtonClick={() => copy(surveyLink)}
        />
      </BoxContainer>
      <BoxContainer>
        <Box
          title={resultsLinkTitle}
          subtitle={resultsLinkSubtitle}
          buttonText={resultsLinkButtonText}
          link={resultsLink}
        />
      </BoxContainer>
    </Boxes>
  </Fragment>
);

LinksSection.propTypes = PropTypes;
LinksSection.defaultProps = DefaultProps;

export default LinksSection;

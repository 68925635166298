import React from 'react';
import { Redirect } from 'react-router-dom';
import { compose } from 'recompose';
import { AbsoluteLoadingSpinner } from '../../components/LoadingSpinner';
import ThanksPage from '../../components/ThanksPage';

import withPrismicMasterRef from '../../hoc/withPrismicMasterRef';
import withPrismicDocument from '../../hoc/withPrismicDocument';
import whenError from '../../hoc/whenError';
import whenLoading from '../../hoc/whenLoading';

export default compose(
  withPrismicMasterRef,
  withPrismicDocument('thanks_page'),
  whenError(<Redirect to={'/'} />),
  whenLoading(AbsoluteLoadingSpinner),
)(ThanksPage);

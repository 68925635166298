import React, { Fragment } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { func, string } from 'prop-types';
import { confirmAlert } from 'react-confirm-alert';
import { Heading4, Ingress } from '../Heading';
import Button from '../Button';
import 'react-confirm-alert/src/react-confirm-alert.css';

const PropTypes = {
  title: string.isRequired,
  message: string.isRequired,
  confirmText: string.isRequired,
  cancelText: string.isRequired,
  onConfirm: func.isRequired,
};

const DefaultProps = {
  message: '',
};

const ConfirmAlertGlobalStyle = createGlobalStyle`
  .react-confirm-alert-overlay {
    background: #1d1d1d;
  }
`;

const Container = styled.div`
  background: white;
  padding: 2.25rem 1.25rem;
`;

const Title = styled(Heading4)``;

const Message = styled(Ingress)`
  margin-top: 1.25rem;
`;

const Buttons = styled.div`
  display: flex;
  flex-flow: row wrap;
  margin-top: 2.5rem;
  padding: 0rem 1rem;
`;

const StyledButton = styled(Button)`
  margin: 0.25rem 2.4375rem 0.25rem 0rem;
`;

const ConfirmAlert = ({ title, message, confirmText, cancelText, onConfirm }) =>
  confirmAlert({
    title,
    message,
    childrenElement: () => <div />,
    customUI: ({
      onClose, // eslint-disable-line react/prop-types
    }) => (
      <Fragment>
        <ConfirmAlertGlobalStyle />
        <Container>
          <Title>{title}</Title>
          <Message>{message}</Message>
          <Buttons>
            <StyledButton
              onClick={() => {
                onConfirm();
                onClose();
              }}
            >
              {confirmText}
            </StyledButton>
            <StyledButton onClick={onClose} outline>
              {cancelText}
            </StyledButton>
          </Buttons>
        </Container>
      </Fragment>
    ),
    willUnmount: () => {},
  });

ConfirmAlert.propTypes = PropTypes;
ConfirmAlert.defaultProps = DefaultProps;

export default ConfirmAlert;

import { compose } from 'recompose';
import Header from '../../components/Header';
import LoadingSpinner from '../../components/LoadingSpinner';
import ErrorPage from '../../components/ErrorPage';
import withPrismicMasterRef from '../../hoc/withPrismicMasterRef';
import withPrismicDocument from '../../hoc/withPrismicDocument';
import whenError from '../../hoc/whenError';
import whenLoading from '../../hoc/whenLoading';

export default compose(
  withPrismicMasterRef,
  withPrismicDocument('header'),
  whenError(ErrorPage),
  whenLoading(LoadingSpinner),
)(Header);

import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { client, ApolloProvider } from './apollo-client-setup';

export default App => props => (
  <ApolloProvider client={client()}>
    <BrowserRouter>
      <App {...props} />
    </BrowserRouter>
  </ApolloProvider>
);

import React from 'react';
import { arrayOf, number, shape, string } from 'prop-types';
import styled from 'styled-components';
import { Heading4 } from '../../Heading';
import CommentSection from '../CommentSection';

import {
  ComponentBoxShadow,
  contentWidthLarge,
  dividerColor,
  fontSizeNormal,
  fontSizeSmall,
  typeColor,
  violet,
  contentWidth,
} from '../../../constants/styles';

const PropTypes = {
  surveyQuestions: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
      options: arrayOf(
        shape({
          label: string.isRequired,
          count: number.isRequired,
          percentage: number.isRequired,
          highlight: string.isRequired,
        }),
      ).isRequired,
    }),
  ),
};

const DefaultProps = {
  surveyQuestions: [],
};

const Container = styled.div`
  background: white;
  box-shadow: ${ComponentBoxShadow};
  margin: 4rem auto 0 auto;
  max-width: ${contentWidthLarge};
  padding: 2.5rem;

  @media (max-width: 500px) {
    padding: 2rem;
  }

  @media (max-width: 450px) {
    padding: 1.5rem;
  }

  @media (max-width: 400px) {
    padding: 1rem;
  }

  @media (max-width: 350px) {
    padding: 0.5rem;
  }
`;

const QuestionContainer = styled.div`
  border-bottom: 1px solid ${dividerColor};
  display: flex;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3rem;
  max-width: ${contentWidth};
  padding-bottom: 0.75rem;
  text-align: left;
`;

const Question = styled.div`
  align-self: flex-end;
  width: 100%;
`;

const QuestionLabel = styled(Heading4)`
  line-height: normal;
  margin-bottom: 0.25rem;
  text-align: left;
`;

const Options = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;

  div {
    flex-basis: 25%;
  }

  @media (max-width: 560px) {
    div {
      flex-basis: 50%;
    }
  }

  @media (max-width: 330px) {
    div {
      flex-basis: 100%;
    }
  }
`;

const OptionContainer = styled.div`
  height: 100%;
  padding: 0.5rem 0rem;
`;

const Option = styled.div`
  color: ${typeColor};
  font-size: ${fontSizeSmall};
  height: 100%;
  padding: 1rem;
  text-align: center;
`;

const Count = styled.div`
  color: ${violet};
  font-family: Gotham;
  font-size: 1.75rem;
  font-weight: 900;
  line-height: normal;
`;

const OptionLabel = styled.div`
  font-size: ${fontSizeNormal};
  line-height: normal;
  margin-top: 1rem;
`;

const Percentage = styled.div`
  font-size: ${fontSizeNormal};
  letter-spacing: 2px;
  line-height: normal;
  margin-top: 0.15rem;
  padding-left: 0.3rem;
`;

const AnswerSection = props => {
  const { surveyQuestions } = props;
  return (
    <Container>
      {surveyQuestions.map(({ key, label: questionLabel, options }, index) => (
        <QuestionContainer key={key}>
          <Question>
            <QuestionLabel>{`${index + 1}. ${questionLabel}`}</QuestionLabel>
            <Options>
              {options.map(
                ({ label: optionLabel, highlight, count, percentage }) => (
                  <OptionContainer key={optionLabel}>
                    <Option>
                      <Count highlight={highlight}>{count}</Count>
                      <OptionLabel>{optionLabel}</OptionLabel>
                      <Percentage>{percentage}%</Percentage>
                    </Option>
                  </OptionContainer>
                ),
              )}
            </Options>
          </Question>
        </QuestionContainer>
      ))}
      <CommentSection {...props} />
    </Container>
  );
};

AnswerSection.propTypes = PropTypes;
AnswerSection.defaultProps = DefaultProps;

export default AnswerSection;

import React from 'react';
import { string } from 'prop-types';
import styled from 'styled-components';
import { Heading1 } from '../../Heading';
import TeaserList from '../../../containers/TeaserList';
import { contentWidth } from '../../../constants/styles';

const PropTypes = {
  teaserTitle: string.isRequired,
};

const DefaultProps = {};

const Container = styled.div`
  margin-top: 6rem;
`;

const TitleContainer = styled.div`
  max-width: ${contentWidth};
  margin-left: auto;
  margin-right: auto;
`;

const Title = styled(Heading1)`
  margin-bottom: 3rem;
  text-align: left;
`;

const TeaserSection = ({ teaserTitle }) => (
  <Container>
    <TitleContainer>
      <Title>{teaserTitle}</Title>
    </TitleContainer>
    <TeaserList />
  </Container>
);

TeaserSection.propTypes = PropTypes;
TeaserSection.defaultProps = DefaultProps;

export default TeaserSection;

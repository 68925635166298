import React from 'react';
import { func, shape, string } from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';
import {
  fontSizeNormal,
  fontSizeH6,
  inputThemes,
  typeColor,
  ComponentRadius,
} from '../../constants/styles';

const PropTypes = {
  label: string.isRequired,
  placeholder: string.isRequired,
  onChange: func.isRequired,
  theme: shape({}),
};

const DefaultProps = {
  theme: inputThemes.light,
};

const Container = styled.div`
  padding-top: 0.5rem;
`;

const StyledTextArea = styled.textarea`
  border-radius: ${ComponentRadius};
  border: 1px solid ${typeColor};
  color: ${typeColor};
  font-size: ${fontSizeNormal};
  height: 12.5rem;
  padding: 1rem;
  width: 100%;

  &:focus,
  &:active {
    border: 2px solid ${props => props.theme.focusBorderColor};
  }

  &:hover,
  &:focus,
  &:active {
    outline: 0;
  }

  &::placeholder {
    color: ${props => props.theme.placeholderColor};
    font-weight: 500;
  }
`;

const Label = styled.label`
  color: ${typeColor};
  font-weight: 500;
  font-size: ${fontSizeH6};
  text-align: center;
  width: 100%;
  margin-left: 2px;
  margin-bottom: 1rem;
  user-select: none;
`;

const TextArea = ({ label, placeholder, onChange, theme }) => (
  <ThemeProvider theme={theme}>
    <Container>
      <Label>{label}</Label>
      <StyledTextArea
        placeholder={placeholder}
        onChange={event => {
          onChange(event.target.value);
        }}
      />
    </Container>
  </ThemeProvider>
);

TextArea.propTypes = PropTypes;
TextArea.defaultProps = DefaultProps;

export default TextArea;

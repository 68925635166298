import orderBy from 'lodash.orderby';
import moment from 'moment-timezone';

export const toPercentage = (amount, total) => {
  if (!amount || !total) {
    return 0;
  }
  const sub = ((amount * 1.0) / total) * 100;
  return Math.round(sub);
};

export const mapQuestionsWithOptions = (questions, options) =>
  questions.map(question => ({
    ...question,
    options: options.filter(option => option.key === question.key),
  }));

export const mapQuestionsWithAnswers = (surveyQuestions, surveyAnswers) => {
  if (!surveyQuestions || !surveyAnswers) {
    return {};
  }

  return surveyQuestions.map(question => {
    const options = question.options.map(option => {
      const count = surveyAnswers.reduce((acc, curr) => {
        const selectedOption = JSON.parse(curr.answerData)[question.key] || 0;
        return selectedOption === option.label ? acc + 1 : acc;
      }, 0);

      return {
        ...option,
        count,
        percentage: toPercentage(count, surveyAnswers.length),
      };
    });

    return {
      ...question,
      options: orderBy(options, 'highlight', 'desc'),
    };
  });
};

export const hasExpired = (createdAt, expirationDays) => {
  if (!createdAt) {
    return false;
  }
  const creationMoment = moment(createdAt);
  const daysSinceCreated = moment().diff(creationMoment, 'days', true);
  return daysSinceCreated >= expirationDays;
};

export default { mapQuestionsWithOptions };

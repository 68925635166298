import { graphql } from 'react-apollo';
import { compose, mapProps, withProps } from 'recompose';
import get from 'lodash.get';
import { AbsoluteLoadingSpinner } from '../../components/LoadingSpinner';
import ResultsPage from '../../components/ResultsPage';
import ResultsErrorPage from '../ResultsErrorPage';
import withPrismicMasterRef from '../../hoc/withPrismicMasterRef';
import withPrismicDocument from '../../hoc/withPrismicDocument';
import whenError from '../../hoc/whenError';
import whenLoading from '../../hoc/whenLoading';

import {
  mapQuestionsWithOptions,
  mapQuestionsWithAnswers,
} from '../../lib/utils';

import {
  getAnswersWithCondition,
  getSurveyWithFilter,
  updateSurveyMutation,
} from '../../graphql';

const apiUrl = process.env.REACT_APP_REST_ENDPOINT || '';

const getSurvey = graphql(getSurveyWithFilter, {
  options: ({
    match: {
      params: { surveyId },
    },
  }) => ({
    variables: {
      filter: {
        or: [
          { surveyId: { equalTo: surveyId } },
          { adminId: { equalTo: surveyId } },
        ],
      },
    },
  }),
  props: ({
    data,
    ownProps: {
      history,
      match: { params },
      location: { state: { surveyClosed = false } = {} },
      ...ownProps
    },
  }) => {
    const survey = get(data, 'allSurveys.nodes[0]', {});
    const { nodeId, surveyId, adminId, status, housingCompany } = survey;

    return {
      ...ownProps,
      data: {
        ...data,
        error: !data.loading && !surveyId ? 'Survey not found' : null,
      },
      surveyClosed,
      nodeId,
      surveyId,
      adminId,
      isAdmin: adminId === params.surveyId,
      status,
      housingCompany,
    };
  },
});

const getAnswers = graphql(getAnswersWithCondition, {
  skip: ({ surveyId }) => !surveyId,
  options: ({ surveyId }) => ({
    variables: {
      answerCondition: {
        surveyId,
      },
    },
  }),
  props: ({
    data,
    ownProps: {
      mainTitle,
      mainSubtitle,
      surveyQuestions,
      housingCompany,
      ...ownProps
    },
  }) => {
    const surveyAnswers = get(data, 'allAnswers.nodes', []);

    const surveyComments = surveyAnswers
      .map(({ answerId, comment }) => ({ answerId, comment }))
      .filter(({ comment }) => comment);

    return {
      ...ownProps,
      data,
      mainTitle: mainTitle.replace('$housingCompany', housingCompany),
      mainSubtitle: mainSubtitle
        ? mainSubtitle.replace('$surveyAnswers.length', surveyAnswers.length)
        : '',
      surveyQuestions: mapQuestionsWithAnswers(surveyQuestions, surveyAnswers),
      surveyComments,
      housingCompany,
    };
  },
});

const handleCloseSurvey = ({
  closeSurveyMutate,
  history,
  nodeId,
  adminId,
}) => async () => {
  try {
    await closeSurveyMutate({
      variables: { nodeId, patch: { status: 'closed' } },
    });
    history.push(`/results/${adminId}`, { surveyClosed: true });
  } catch (err) {
    console.error(err); // eslint-disable-line no-console
    throw err;
  }
};

const closeSurvey = graphql(updateSurveyMutation, {
  name: 'closeSurveyMutate',
  props: ({ closeSurveyMutate, ownProps: { history, nodeId, adminId } }) => ({
    onCloseSurvey: handleCloseSurvey({
      closeSurveyMutate,
      history,
      nodeId,
      adminId,
    }),
  }),
});

export default compose(
  withPrismicMasterRef,
  withPrismicDocument('survey_page'),
  mapProps(({ surveyQuestions = [], surveyOptions = [], ...rest }) => ({
    ...rest,
    surveyQuestions: mapQuestionsWithOptions(surveyQuestions, surveyOptions),
  })),
  withPrismicDocument('results_page'),
  mapProps(({ whatNextItems = [], ...rest }) => ({
    ...rest,
    whatNextItems: whatNextItems.map(
      ({ button_text: buttonText, ...item }) => ({
        ...item,
        buttonText,
      }),
    ),
  })),
  whenError(ResultsErrorPage),
  whenLoading(AbsoluteLoadingSpinner),
  getSurvey,
  whenError(ResultsErrorPage),
  whenLoading(AbsoluteLoadingSpinner),
  getAnswers,
  whenError(ResultsErrorPage),
  whenLoading(AbsoluteLoadingSpinner),
  closeSurvey,
  whenError(ResultsErrorPage),
  whenLoading(AbsoluteLoadingSpinner),
  withProps(({ surveyId }) => ({
    reportLink: `${apiUrl}/report/${surveyId}`,
  })),
)(ResultsPage);

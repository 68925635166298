import { compose } from 'recompose';
import ResultsErrorPage from '../../components/ResultsErrorPage';
import LoadingSpinner from '../../components/LoadingSpinner';
import ErrorPage from '../../components/ErrorPage';
import withPrismicMasterRef from '../../hoc/withPrismicMasterRef';
import withPrismicDocument from '../../hoc/withPrismicDocument';
import whenError from '../../hoc/whenError';
import whenLoading from '../../hoc/whenLoading';

export default compose(
  withPrismicMasterRef,
  withPrismicDocument('results_error_page'),
  whenError(ErrorPage),
  whenLoading(LoadingSpinner),
)(ResultsErrorPage);

import gql from 'graphql-tag';
import { answerFragment, surveyFragment } from './fragments';

export const getAnswersWithCondition = gql`
  query($answerCondition: AnswerCondition) {
    allAnswers(condition: $answerCondition) {
      nodes {
        ...answerFragment
      }
    }
  }
  ${answerFragment}
`;

export const getSurveyWithCondition = gql`
  query getSurvey($condition: SurveyCondition) {
    allSurveys(condition: $condition) {
      nodes {
        ...surveyFragment
      }
    }
  }
  ${surveyFragment}
`;

export const getSurveyWithFilter = gql`
  query($filter: SurveyFilter) {
    allSurveys(filter: $filter) {
      nodes {
        ...surveyFragment
      }
    }
  }
  ${surveyFragment}
`;

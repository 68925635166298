import { graphql } from 'react-apollo';
import { compose } from 'recompose';
import uuidv4 from 'uuid/v4';
import { AbsoluteLoadingSpinner } from '../../components/LoadingSpinner';
import LandingPage from '../../components/LandingPage';
import ErrorPage from '../../components/ErrorPage';
import withPrismicMasterRef from '../../hoc/withPrismicMasterRef';
import withPrismicDocument from '../../hoc/withPrismicDocument';
import whenError from '../../hoc/whenError';
import whenLoading from '../../hoc/whenLoading';
import { createSurveyMutation } from '../../graphql';

const handleCreateSurvey = ({
  createSurveyMutate,
  history,
  surveyId,
  adminId,
  status,
}) => async survey => {
  try {
    const mutationResult = await createSurveyMutate({
      variables: {
        survey: {
          ...survey,
          surveyId,
          adminId,
          status,
        },
      },
    });
    const { REACT_APP_REST_ENDPOINT: API_URL } = process.env;
    await fetch(`${API_URL}/mail/new-survey`, {
      method: 'POST',
      body: JSON.stringify(mutationResult.data.createSurvey),
      headers: {
        'Content-Type': 'application/json',
      },
    });

    history.push(`/survey/${surveyId}`, { surveyCreated: true });
  } catch (err) {
    console.error(err); // eslint-disable-line no-console
    throw err;
  }
};

const createSurvey = graphql(createSurveyMutation, {
  name: 'createSurveyMutate',
  props: ({ createSurveyMutate, ownProps: { history } }) => ({
    onCreateSurvey: handleCreateSurvey({
      createSurveyMutate,
      history,
      surveyId: uuidv4(),
      adminId: uuidv4(),
      status: 'open',
    }),
  }),
});

export default compose(
  withPrismicMasterRef,
  withPrismicDocument('landing_page'),
  createSurvey,
  whenLoading(AbsoluteLoadingSpinner),
  whenError(ErrorPage),
)(LandingPage);

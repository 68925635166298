import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { string, bool, element, oneOfType, array, func } from 'prop-types';

import {
  buttonThemes,
  fontFamilySansSerif,
  ComponentRadius,
} from '../../constants/styles';

const PropTypes = {
  children: oneOfType([element, string, array]).isRequired,
  link: bool,
  disabled: bool,
  fullWidth: bool,
  hash: bool,
  scroll: func,
  protocolLink: bool,
};

const DefaultProps = {
  link: false,
  disabled: false,
  fullWidth: false,
  hash: false,
  scroll: null,
  protocolLink: false,
};

const StyledButton = styled.button`
  background-color: ${props =>
    !props.outline ? props.theme.backgroundColor : 'white'};
  color: ${props =>
    props.outline ? props.theme.backgroundColor : props.theme.color};
  border: ${props =>
    props.outline ? `2px solid ${props.theme.backgroundColor}` : 'none'};
  border-radius: ${ComponentRadius};
  font-size: 1rem;
  font-family: ${fontFamilySansSerif};
  font-weight: ${props => (props.outline ? '700' : '800')};
  margin: 0;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
  display: inline-block;
  line-height: 1.15;
  outline: none;
  position: relative;
  padding: 0.9375rem 1.875rem;
  width: ${props => (props.fullWidth ? '100%' : 'auto')};
  height: 48px;
  pointer-events: ${props => (props.disabled ? 'none' : 'inherit')};

  &:after {
    content: '';
    position: absolute;
    z-index: -1;
  }

  &:hover,
  &:active {
    background-color: ${props =>
      !props.outline ? props.theme.hover : 'white'};
    border-color: ${props =>
      props.outline ? props.theme.hover : 'transparent'};
    color: ${props => (props.outline ? props.theme.hover : props.theme.color)};
    text-decoration: none;
  }

  &:focus {
    outline: 0;
  }

  &:disabled {
    background-color: ${props => props.theme.disabled};
    border: none;
    color: ${props => props.theme.disabledColor};
  }

  &:disabled:hover,
  &:disabled:active {
    background: ${props => props.theme.disabled};
    border: none;
    color: ${props => props.theme.disabledColor};
    text-decoration: none;
  }
`;

const StyledLink = styled(
  StyledButton.withComponent(({ outline, ...props }) => <Link {...props} />),
)`
  flex: 1;
`;

const StyledProtocolLink = styled(StyledButton.withComponent('a'))`
  flex: 1;
`;

const StyledHashLink = styled(
  StyledButton.withComponent(({ outline, ...props }) => (
    <HashLink {...props} />
  )),
)`
  flex: 1;
`;

const getTheme = ({ danger, neutral }) => {
  if (danger) {
    return buttonThemes.danger;
  }

  if (neutral) {
    return buttonThemes.neutral;
  }

  return buttonThemes.primary;
};

const Button = ({
  children,
  link,
  fullWidth,
  hash,
  scroll,
  protocolLink,
  ...rest
}) => {
  if (hash) {
    return (
      <ThemeProvider theme={getTheme({ ...rest })}>
        <StyledHashLink
          style={{ width: fullWidth ? '100%' : 'auto' }}
          scroll={scroll}
          {...rest}
        >
          {children}
        </StyledHashLink>
      </ThemeProvider>
    );
  }

  if (protocolLink) {
    return (
      <ThemeProvider theme={getTheme({ ...rest })}>
        <StyledProtocolLink
          style={{ width: fullWidth ? '100%' : 'auto' }}
          {...rest}
        >
          {children}
        </StyledProtocolLink>
      </ThemeProvider>
    );
  }

  if (link) {
    return (
      <ThemeProvider theme={getTheme({ ...rest })}>
        <StyledLink style={{ width: fullWidth ? '100%' : 'auto' }} {...rest}>
          {children}
        </StyledLink>
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={getTheme({ ...rest })}>
      <StyledButton fullWidth={fullWidth} {...rest}>
        {children}
      </StyledButton>
    </ThemeProvider>
  );
};

Button.propTypes = PropTypes;
Button.defaultProps = DefaultProps;

export default Button;

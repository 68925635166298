import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { stringify } from 'qs';
import camelCase from 'lodash.camelcase';

const camelCaseKeys = obj =>
  Object.keys(obj).reduce(
    (ccObj, field) => ({
      ...ccObj,
      [camelCase(field)]: obj[field],
    }),
    {},
  );

const getDocumentQuery = gql`
  query($path: String) {
    getDocument(path: $path) @rest(type: "Document", path: "{args.path}") {
      results @type(name: Results)
    }
  }
`;

const withDocument = (component, page) =>
  graphql(getDocumentQuery, {
    skip: ({ masterRef = null }) => !masterRef,
    options: ({ masterRef = null }) => {
      const params = {
        q: `[[at(document.type, "${page}")]]`,
      };
      return {
        variables: {
          path: `/documents/search?ref=${masterRef}&${stringify(params)}`,
        },
      };
    },
    props: ({ data: { getDocument: { results = {} } = {}, ...data } = {} }) => {
      const doc = results[0] || {};
      const types = doc.data || {};
      return {
        data,
        ...camelCaseKeys(types),
      };
    },
  })(component);

export default page => component => withDocument(component, page);

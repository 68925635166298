import React from 'react';
import { func, string } from 'prop-types';
import styled from 'styled-components';
import Button from '../Button';
import { Heading4, Ingress } from '../Heading';
import {
  ComponentBoxShadow,
  ComponentRadius,
  typeColor,
  fontSizeIngressSmall,
} from '../../constants/styles';

const PropTypes = {
  title: string.isRequired,
  subtitle: string.isRequired,
  buttonText: string.isRequired,
  link: string,
  onButtonClick: func,
};

const DefaultProps = {
  link: '',
  onButtonClick: null,
};

const Container = styled.div`
  align-items: center;
  background-color: white;
  border-radius: ${ComponentRadius};
  box-shadow: ${ComponentBoxShadow};
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  margin: 1.5rem 0;
  padding: 2.25rem 0;
  text-align: center;
`;

const Title = styled(Heading4)`
  text-transform: uppercase;
`;

const Subtitle = styled(Ingress)`
  color: ${typeColor};
  font-size: ${fontSizeIngressSmall};
  margin-bottom: 1rem;
  margin-top: 0.5rem;
  max-width: 500px;
  min-height: 56px;
`;

const Link = styled.a`
  color: white;
  text-decoration: none;
  margin-top: 1rem;
`;

const Box = ({ title, subtitle, buttonText, link, onButtonClick }) => (
  <Container>
    <Title>{title}</Title>
    <Subtitle>{subtitle}</Subtitle>
    <Link href={link}>
      <Button
        onClick={event => {
          if (onButtonClick) {
            onButtonClick(event);
          }
        }}
      >
        {buttonText}
      </Button>
    </Link>
  </Container>
);

Box.propTypes = PropTypes;
Box.defaultProps = DefaultProps;

export default Box;

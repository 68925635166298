import React from 'react';
import { arrayOf, number, shape, string } from 'prop-types';
import styled from 'styled-components';
import { Heading4 } from '../../Heading';
import {
  contentWidth,
  fontSizeSmall,
  typeColor,
} from '../../../constants/styles';

const PropTypes = {
  surveyQuestions: arrayOf(shape({})).isRequired,
  surveyCommentTitle: string.isRequired,
  surveyCommentImage: shape({
    url: string.isRequired,
  }),
  surveyComments: arrayOf(
    shape({
      answerId: number.isRequired,
      comment: string.isRequired,
    }),
  ),
  noCommentsText: string.isRequired,
};

const DefaultProps = {
  surveyCommentImage: {},
  surveyComments: [],
};

const Container = styled.div`
  margin-bottom: 5rem;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3rem;
  max-width: ${contentWidth};
`;

const Title = styled(Heading4)`
  margin-bottom: 2.5rem;
  text-align: left;
`;

const NoComments = styled.div`
  color: ${typeColor};
  font-size: ${fontSizeSmall};
  text-align: left;
  margin-left: 1rem;
`;

const CommentContainer = styled.div`
  display: flex;
  margin-left: 1rem;
  margin-top: 2rem;
`;

const ImageContainer = styled.div``;

const Comment = styled.div`
  color: ${typeColor};
  font-size: ${fontSizeSmall};
  line-height: 1.5;
  margin-left: 3rem;
  margin-top: 4px;
  max-width: 600px;
  text-align: left;
`;

const CommentSection = ({
  surveyQuestions,
  surveyCommentTitle,
  surveyCommentImage,
  surveyComments,
  noCommentsText,
}) => (
  <Container>
    <Title>{`${surveyQuestions.length + 1}. ${surveyCommentTitle}`}</Title>
    {surveyComments.length === 0 ? (
      <NoComments>{noCommentsText}</NoComments>
    ) : null}
    {surveyComments.map(({ answerId, comment }) => (
      <CommentContainer key={answerId}>
        <ImageContainer>
          {surveyCommentImage ? (
            <img src={surveyCommentImage.url} alt="quote" />
          ) : null}
        </ImageContainer>
        <Comment>{comment}</Comment>
      </CommentContainer>
    ))}
  </Container>
);

CommentSection.propTypes = PropTypes;
CommentSection.defaultProps = DefaultProps;

export default CommentSection;

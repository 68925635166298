import gql from 'graphql-tag';

export const answerFragment = gql`
  fragment answerFragment on Answer {
    answerId
    surveyId
    answerData
    comment
  }
`;

export const surveyFragment = gql`
  fragment surveyFragment on Survey {
    nodeId
    surveyId
    adminId
    status
    housingCompany
    contact
    phone
    email
    createdAt
  }
`;

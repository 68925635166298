import React from 'react';
import { bool, string } from 'prop-types';
import styled from 'styled-components';
import { Heading1, Ingress } from '../../Heading';
import { contentWidth } from '../../../constants/styles';

const PropTypes = {
  surveyCreated: bool.isRequired,
  surveyTitle: string.isRequired,
  surveySubtitle: string.isRequired,
  surveyCreatedTitle: string.isRequired,
  surveyCreatedSubtitle: string.isRequired,
};

const DefaultProps = {};

const Container = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: ${contentWidth};
  text-align: left;
`;

const Title = styled(Heading1)`
  line-height: 1.15;
  text-align: left;
  margin-top: 2rem;
`;

const Subtitle = styled(Ingress)`
  margin-top: 1.5rem;
`;

const TitleSection = ({
  surveyCreated,
  surveyTitle,
  surveySubtitle,
  surveyCreatedTitle,
  surveyCreatedSubtitle,
}) => (
  <Container>
    <Title>{surveyCreated ? surveyCreatedTitle : surveyTitle}</Title>
    <Subtitle>
      {surveyCreated ? surveyCreatedSubtitle : surveySubtitle}
    </Subtitle>
  </Container>
);

TitleSection.propTypes = PropTypes;
TitleSection.defaultProps = DefaultProps;

export default TitleSection;

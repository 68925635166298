import React from 'react';
import { bool, string } from 'prop-types';
import styled from 'styled-components';
import PageWrapper from '../PageWrapper';
import TopSection from './TopSection';
import AnswersSection from './AnswersSection';
import CloseSurveySection from './CloseSurveySection';
import WhatNextSection from './WhatNextSection';
import TeaserSection from './TeaserSection';
import { frameWidthLarge } from '../../constants/styles';

const PropTypes = {
  housingCompany: string.isRequired,
  mainSubtitle: string.isRequired,
  status: string.isRequired,
  surveyClosedText: string.isRequired,
  isAdmin: bool.isRequired,
};

const DefaultProps = {};

const Frame = styled.div`
  margin-left: auto;
  margin-right: auto;
  margin-top: 1rem;
  max-width: ${frameWidthLarge};
  padding-bottom: 10rem;
  text-align: center;
`;

const ResultsPage = props => {
  const { isAdmin } = props;
  return (
    <Frame>
      <TopSection {...props} />
      <AnswersSection {...props} />
      {isAdmin ? <CloseSurveySection {...props} /> : null}
      <WhatNextSection {...props} />
      <TeaserSection {...props} />
    </Frame>
  );
};

ResultsPage.propTypes = PropTypes;
ResultsPage.defaultProps = DefaultProps;

export default props => (
  <PageWrapper>
    <ResultsPage {...props} />
  </PageWrapper>
);

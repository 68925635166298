import React, { PureComponent } from 'react';
import { arrayOf, func, shape, string } from 'prop-types';
import styled from 'styled-components';
import { mediaBreakpointDownSm } from 'styled-bootstrap-responsive-breakpoints';
import omit from 'lodash.omit';
import Input from '../Input';
import Button from '../Button';
import PageWrapper from '../PageWrapper';
import { Heading1, Heading4, Ingress } from '../Heading';
import {
  ComponentBoxShadow,
  contentWidth,
  frameWidth,
  fontSizeSmaller,
  inputThemes,
} from '../../constants/styles';
import Checkbox from '../Checkbox';

const PropTypes = {
  headerTitle: string.isRequired,
  headerSubtitle: string,
  surveyTitle: string.isRequired,
  surveyInputs: arrayOf(
    shape({
      type: string.isRequired,
      label: string.isRequired,
      placeholder: string.isRequired,
    }),
  ).isRequired,
  surveySubmitText: string.isRequired,
  onCreateSurvey: func.isRequired,
};

const DefaultProps = {
  headerSubtitle: '',
};

const Frame = styled.div`
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 8.5rem;
`;

const Header = styled.div`
  max-width: ${contentWidth};
  margin: 0 auto;
  text-align: left;
  line-height: 1.05;
`;

const HeaderTitle = styled(Heading1)`
  text-align: left;
`;

const HeaderIngress = styled(Ingress)`
  margin-top: 1.75rem;
  font-size: 1.25rem;
`;

const Card = styled.div`
  max-width: ${frameWidth};
  margin: 0 auto;
  background: white;
  box-shadow: ${ComponentBoxShadow};
`;

const Survey = styled.div`
  margin-top: 3.5rem;
  max-width: ${contentWidth};
  background: white;
  margin 3.5rem auto 0 auto;
  padding: 4.5rem 1rem;
`;

const SurveyTitle = styled(Heading4)`
  margin-bottom: 0.25rem;
  letter-spacing: -1px;
  padding-left: 0rem;
`;

const Inputs = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin-top: 1.5rem;
  margin-bottom: 2rem;

  div {
    flex-basis: 46%;
    padding: 1rem 0rem;
  }

  ${mediaBreakpointDownSm`
    div {
      flex-basis: 100%;
      padding: 0.5 rem;
    }
  `};
`;

const Error = styled.div`
  background-color: transparent;
  padding-bottom: 1.5rem;
  color: ${inputThemes.light.errorMessageTextColor};
  font-size: ${fontSizeSmaller};
  font-weight: 500;
  margin-left: -0.5rem;
  margin-top: 0.35rem;
`;

const Centered = styled.div`
  text-align: center;
`;

const SubmitButton = styled(Button)`
  margin-top: 2rem;
`;

class LandingPage extends PureComponent {
  state = {
    survey: {},
    error: null,
  };

  onChange = (key, value) => {
    this.setState(prevState => ({
      survey: {
        ...prevState.survey,
        [key]: value,
      },
      error: null,
    }));
  };

  onSubmit = async () => {
    const { consentError } = this.props;
    const { survey } = this.state;

    if (!survey.consent) {
      this.setState(prevState => ({
        ...prevState,
        error: consentError,
      }));

      return;
    }

    const inputs = omit(survey, 'consent');

    if (Object.keys(inputs).length) {
      await this.props.onCreateSurvey(inputs);
    }
  };

  render() {
    const {
      headerTitle,
      headerSubtitle,
      surveyTitle,
      surveyInputs,
      surveySubmitText,
      consentText,
    } = this.props;
    const { error } = this.state;
    return (
      <Frame>
        <Header>
          <HeaderTitle>{headerTitle}</HeaderTitle>
          <HeaderIngress>{headerSubtitle}</HeaderIngress>
        </Header>
        <Card>
          <Survey>
            <SurveyTitle>{surveyTitle}</SurveyTitle>
            <Inputs>
              {surveyInputs.map(({ key, ...rest }) => (
                <Input
                  key={key}
                  onChange={event => {
                    this.onChange(key, event.target.value);
                  }}
                  {...rest}
                />
              ))}
            </Inputs>
            <Centered>
              <Checkbox
                id="consent"
                onChange={event => {
                  this.onChange('consent', event.target.checked);
                }}
                label={consentText}
              />
              {error && <Error>{error}</Error>}
              <SubmitButton onClick={this.onSubmit}>
                {surveySubmitText}
              </SubmitButton>
            </Centered>
          </Survey>
        </Card>
      </Frame>
    );
  }
}

LandingPage.propTypes = PropTypes;
LandingPage.defaultProps = DefaultProps;

export default props => (
  <PageWrapper>
    <LandingPage {...props} />
  </PageWrapper>
);

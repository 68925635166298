import React from 'react';
import { string } from 'prop-types';
import styled from 'styled-components';
import PageWrapper from '../PageWrapper';
import { Heading2, Ingress } from '../Heading';
import { contentWidthLarge } from '../../constants/styles';

const PropTypes = {
  title: string.isRequired,
  subtitle: string.isRequired,
};

const DefaultProps = {};

const Frame = styled.div`
  text-align: center;
  max-width: ${contentWidthLarge};
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 5rem;
  min-height: 50vh;
  display: flex;
`;

const Container = styled.div`
  margin-left: auto;
  margin-right: auto;
  align-self: center;
`;

const Title = styled(Heading2)``;

const Subtitle = styled(Ingress)`
  margin-top: 1rem;
  margin-left: auto;
  margin-right: auto;
  line-height: 1.4;
`;

const SurveyErrorPage = ({ title, subtitle }) => (
  <Frame>
    <Container>
      <Title>{title}</Title>
      <Subtitle>{subtitle}</Subtitle>
    </Container>
  </Frame>
);

SurveyErrorPage.propTypes = PropTypes;
SurveyErrorPage.defaultProps = DefaultProps;

export default props => (
  <PageWrapper>
    <SurveyErrorPage {...props} />
  </PageWrapper>
);

import React from 'react';
import styled from 'styled-components';
import { arrayOf, shape, string } from 'prop-types';
import { fontSizeNormal } from '../../constants/styles';

const PropTypes = {
  logo: shape({
    url: string.isRequired,
  }).isRequired,
  links: arrayOf(
    shape({
      href: string.isRequired,
      name: string.isRequired,
    }),
  ).isRequired,
};

const Container = styled.footer`
  text-align: center;
  width: 100%;
  background: black;
`;

const Logo = styled.img`
  position: relative;
  top: -34px;
`;

const Links = styled.div`
  display: flex;
  justify-content: space-around;
  max-width: 400px;
  margin-top: 1.5rem;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 4.5rem;
`;

const Link = styled.a`
  color: white;
  font-size: ${fontSizeNormal};
  font-weight: 500;

  &:hover {
    color: white;
  }
`;

const Copyright = styled.div`
  text-align: center;
  color: white;
  padding-bottom: 1.75rem;
  line-height: normal;
  font-family: 'Gotham';
`;

const Footer = ({ logo, links }) => (
  <Container>
    <Logo src={logo.url} alt="logo" />
    <Links>
      {links.map(({ href, name }) => (
        <Link key={name} href={href}>
          {name}
        </Link>
      ))}
    </Links>
    <Copyright>© Helen Oy</Copyright>
  </Container>
);

Footer.propTypes = PropTypes;

export default Footer;

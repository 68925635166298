import React from 'react';
import { bool, func, string } from 'prop-types';
import styled from 'styled-components';
import { Heading1, Ingress } from '../../Heading';
import Button from '../../Button';
import ConfirmAlert from '../../ConfirmAlert';

import {
  brandDangerColor,
  contentWidth,
  fontSizeNormal,
  typeColor,
} from '../../../constants/styles';

const PropTypes = {
  closeSurveyButtonText: string.isRequired,
  closeSurveySubtitle: string.isRequired,
  closeSurveyTitle: string.isRequired,
  confirmAlertCancelButtonText: string.isRequired,
  confirmAlertConfirmButtonText: string.isRequired,
  confirmAlertMessage: string.isRequired,
  confirmAlertTitle: string.isRequired,
  onCloseSurvey: func.isRequired,
  status: string.isRequired,
  surveyClosed: bool.isRequired,
  surveyClosedText: string.isRequired,
};

const DefaultProps = {};

const Container = styled.div`
  margin: 5.5rem auto 0rem auto;
  max-width: ${contentWidth};
  text-align: left;
`;

const Title = styled(Heading1)`
  margin-bottom: 1rem;
  text-align: left;
`;

const Subtitle = styled(Ingress)`
  font-size: ${fontSizeNormal};
  color: ${typeColor};
  max-width: 550px;
  text-align: left;
  margin-bottom: 2rem;
`;

const BottomSection = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: safe;
  margin-left: 1rem;
`;

const CloseButton = styled(Button)``;

const Closed = styled.div`
  align-self: center;
  color: ${brandDangerColor};
  margin-left: 1rem;
`;

const CloseSurveySection = ({
  closeSurveyButtonText,
  closeSurveySubtitle,
  closeSurveyTitle,
  confirmAlertCancelButtonText,
  confirmAlertConfirmButtonText,
  confirmAlertMessage,
  confirmAlertTitle,
  onCloseSurvey,
  status,
  surveyClosed,
  surveyClosedText,
}) => (
  <Container>
    <Title>{closeSurveyTitle}</Title>
    <Subtitle>{closeSurveySubtitle}</Subtitle>
    <BottomSection>
      <CloseButton
        onClick={() => {
          if (onCloseSurvey) {
            ConfirmAlert({
              title: confirmAlertTitle,
              message: confirmAlertMessage,
              confirmText: confirmAlertConfirmButtonText,
              cancelText: confirmAlertCancelButtonText,
              onConfirm: () => {
                onCloseSurvey();
              },
            });
          }
        }}
        disabled={surveyClosed}
      >
        {closeSurveyButtonText}
      </CloseButton>
      {surveyClosed || status === 'closed' ? (
        <Closed>{surveyClosedText}</Closed>
      ) : null}
    </BottomSection>
  </Container>
);

CloseSurveySection.propTypes = PropTypes;
CloseSurveySection.defaultProps = DefaultProps;

export default CloseSurveySection;

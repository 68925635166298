import React from 'react';
import { arrayOf, shape, string } from 'prop-types';
import styled from 'styled-components';
import { Heading5 } from '../Heading';
import {
  ComponentBoxShadow,
  fontSizeTiny,
  frameWidthLarge,
  typeColor,
  contentWidth,
} from '../../constants/styles';

const PropTypes = {
  items: arrayOf(
    shape({
      id: string.isRequired,
      image: shape({
        url: string.isRequired,
      }).isRequired,
      category: string.isRequired,
      title: string.isRequired,
      link: string.isRequired,
    }),
  ).isRequired,
};

const Container = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  margin-left: auto;
  margin-right: auto;
  max-width: ${frameWidthLarge};
  text-align: left;

  @media (max-width: 1179px) {
    max-width: ${contentWidth};
  }
`;

const ItemWrapper = styled.div`
  margin: 0.5rem;

  @media (max-width: 1179px) {
    flex-basis: 100%;
    margin: 0.5rem 1rem;
  }
`;

const Item = styled.div`
  background: white;
  box-shadow: ${ComponentBoxShadow};
  height: 100%;
  max-width: 370px;
`;

const Image = styled.img`
  height: auto;
  width: 100%;
`;

const ItemBottomSection = styled.div`
  padding: 1rem 1.5rem;
`;

const Category = styled.div`
  color: ${typeColor};
  font-family: Gotham;
  font-size: ${fontSizeTiny};
  font-weight: 500;
  letter-spacing: 2px;
  margin-top: 0.35rem;
  text-transform: uppercase;
`;

const Title = styled(Heading5)`
  color: ${typeColor};
  font-weight: 700;
  margin-top: 0.85rem;
  padding-left: 0;
  padding-right: 0;
`;

const Link = styled.a`
  color: ${typeColor};

  &:hover {
    color: ${typeColor};
  }
`;

const TeaserList = ({ items }) => (
  <Container>
    {items.map(({ id, image, category, title, link }) => (
      <ItemWrapper key={id}>
        <Item>
          <Link href={link}>
            <Image src={image.url} alt="image" />
            <ItemBottomSection>
              <Category>{category}</Category>
              <Title>{title}</Title>
            </ItemBottomSection>
          </Link>
        </Item>
      </ItemWrapper>
    ))}
  </Container>
);

TeaserList.propTypes = PropTypes;

export default TeaserList;

import React from 'react';
import styled from 'styled-components';
import { shape, string } from 'prop-types';
import { Heading6 } from '../Heading';

const propTypes = {
  data: shape({
    error: shape({
      message: string,
    }).isRequired,
  }).isRequired,
};

const Error = styled(Heading6)`
  text-align: left;
  padding: 1rem;
`;

const ErrorPage = ({ data: { error } }) => <Error>{error}</Error>;

ErrorPage.propTypes = propTypes;

export default ErrorPage;

import React from 'react';
import { string, number, oneOf, oneOfType, shape } from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';
import {
  brandDangerColor,
  fontFamilySansSerif,
  fontSizeNormal,
  fontSizeSmall,
  inputThemes,
  ComponentRadius,
} from '../../constants/styles';

const PropTypes = {
  label: string,
  id: oneOfType([string, number]),
  type: oneOf(['text', 'password', 'email', 'number']),
  error: string,
  theme: shape({}),
  autoComplete: string,
};

const DefaultProps = {
  label: null,
  id: null,
  type: 'text',
  error: null,
  theme: inputThemes.light,
  autoComplete: 'on',
};

export const StyledInput = styled.input`
  color: ${props => props.theme.inputColor};
  border: 1px solid
    ${props => (props.error ? brandDangerColor : props.theme.borderColor)};
  border-radius: ${ComponentRadius};
  padding: 0.825rem 0.5rem 0.825rem 0.75rem;
  font-size: ${fontSizeSmall};
  font-family: ${fontFamilySansSerif};
  font-weight: 400;
  width: 100%;
  line-height: 1.5;
  display: inline-block;
  background-color: ${props => props.theme.backgroundColor};

  &:focus,
  &:active {
    outline: 0;
    background-color: ${props => props.theme.backgroundColorFocus};
    border-width: 2px;
    margin-bottom: -2px;
    border-color: ${props => props.theme.focusBorderColor};
  }
  &:disabled {
    cursor: not-allowed;
    background: ${props => props.theme.backgroundColorDisabled};
  }
  &::placeholder {
    color: ${props => props.theme.placeholderColor};
    font-weight: 500;
  }
`;

export const StyledLabel = styled.label`
  color: ${props => (props.error ? brandDangerColor : props.theme.labelColor)};
  font-weight: 500;
  font-size: ${fontSizeNormal};
  font-family: ${fontFamilySansSerif};
  text-align: left;
  width: 100%;
  margin-left: 2px;
`;

export const Message = styled.div`
  background-color: ${props =>
    props.error ? props.theme.errorMessageBackgroundColor : 'transparent'};
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-top: 0.5rem;
  font-family: ${fontFamilySansSerif};
  color: ${props =>
    props.error ? props.theme.errorMessageTextColor : props.theme.labelColor};
  font-size: ${fontSizeNormal};
`;

const Input = ({
  theme = inputThemes.light,
  label,
  id,
  type,
  error,
  autoComplete,
  ...rest
}) => (
  <ThemeProvider theme={theme}>
    <div>
      {label && (
        <StyledLabel error={error} hidden={rest.hidden || false} htmlFor={id}>
          {label}
        </StyledLabel>
      )}
      <StyledInput
        id={id}
        type={type}
        error={error}
        autoComplete={autoComplete}
        {...rest}
      />
      {error && <Message error={error}>{error}</Message>}
    </div>
  </ThemeProvider>
);

Input.propTypes = PropTypes;
Input.defaultProps = DefaultProps;

export default Input;

import styled from 'styled-components';
import {
  fontSizeH1,
  fontSizeH2,
  fontSizeH3,
  fontSizeH4,
  fontSizeH5,
  fontSizeH6,
  fontSizeIngressSmall,
  typeColor,
  typeColorLight,
} from '../../constants/styles';

const Heading1 = styled.h1`
  color: ${typeColor};
  font-size: ${fontSizeH1};
  font-weight: 700;
  line-height: normal;
  padding-left: 1rem;
  padding-right: 1rem;
  text-transform: uppercase;
  white-space: pre-wrap;
  word-wrap: break-word;

  @media (max-width: 436px) {
    font-size: 2.375rem;
  }

  @media (max-width: 406px) {
    font-size: 2.0625rem;
  }

  @media (max-width: 356px) {
    font-size: 1.8125rem;
  }
`;

const Heading2 = styled.h2`
  color: ${typeColor};
  font-size: ${fontSizeH2};
  font-weight: 900;
  line-height: normal;
  padding-left: 1rem;
  padding-right: 1rem;
  text-transform: uppercase;
  white-space: pre-wrap;
  word-wrap: break-word;
`;

const Heading3 = styled.h3`
  color: ${typeColor};
  font-size: ${fontSizeH3};
  font-weight: 500;
  line-height: 1.4;
  padding-left: 1rem;
  padding-right: 1rem;
  text-transform: none;
  white-space: pre-wrap;
  word-wrap: break-word;
`;

const Heading4 = styled.h4`
  color: ${typeColor};
  font-size: ${fontSizeH4};
  font-weight: 700;
  line-height: 1.2;
  padding-left: 1rem;
  padding-right: 1rem;
  text-transform: uppercase;
  white-space: pre-wrap;
  word-wrap: break-word;
`;

const Heading5 = styled.h5`
  color: ${typeColorLight};
  font-size: ${fontSizeH5};
  font-weight: 500;
  line-height: 1.2;
  padding-left: 1rem;
  padding-right: 1rem;
  text-transform: none;
  white-space: pre-wrap;
  word-wrap: break-word;
`;

const Heading6 = styled.h6`
  color: ${typeColorLight};
  font-size: ${fontSizeH6};
  font-weight: 500;
  line-height: 1.9;
  padding-left: 1rem;
  padding-right: 1rem;
  text-transform: none;
  white-space: pre-wrap;
  word-wrap: break-word;
`;

const Ingress = styled.h6`
  color: ${typeColor};
  font-size: ${fontSizeIngressSmall};
  font-weight: 400;
  line-height: 1.4;
  padding-left: 1rem;
  padding-right: 1rem;
  text-transform: none;
  white-space: pre-wrap;
  word-wrap: break-word;
`;

export { Heading1, Heading2, Heading3, Heading4, Heading5, Heading6, Ingress };

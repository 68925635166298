import React, { Fragment } from 'react';
import styled from 'styled-components';
import { shape } from 'prop-types';
import Header from '../../containers/Header';
import Footer from '../../containers/Footer';
import { appBackgroundColor } from '../../constants/styles';

const PropTypes = {
  children: shape({}).isRequired,
};

const Background = styled.div`
  width: 100%;
  background-color: ${appBackgroundColor};
`;

const PageWrapper = ({ children }) => (
  <Fragment>
    <Background>
      <Header />
      {children}
    </Background>
    <Footer />
  </Fragment>
);

PageWrapper.propTypes = PropTypes;

export default PageWrapper;

import { graphql } from 'react-apollo';
import { branch, compose, mapProps, renderComponent } from 'recompose';
import get from 'lodash.get';
import { AbsoluteLoadingSpinner } from '../../components/LoadingSpinner';
import SurveyPage from '../../components/SurveyPage';
import SurveyErrorPage from '../SurveyErrorPage';
import withPrismicMasterRef from '../../hoc/withPrismicMasterRef';
import withPrismicDocument from '../../hoc/withPrismicDocument';
import whenError from '../../hoc/whenError';
import whenLoading from '../../hoc/whenLoading';
import { hasExpired, mapQuestionsWithOptions } from '../../lib/utils';

import {
  createAnswerMutation,
  getSurveyWithCondition,
  updateSurveyMutation,
} from '../../graphql';

const baseUrl = process.env.REACT_APP_BASE_URL || '';
const deleteDays = Number(process.env.CLOSE_SURVEYS_OLDER_THAN_DAYS) || 180;

const getSurvey = graphql(getSurveyWithCondition, {
  options: ownProps => ({
    variables: {
      condition: {
        surveyId: ownProps.match.params.surveyId,
        status: 'open',
      },
    },
  }),
  props: ({
    data,
    ownProps: {
      match: {
        params: { surveyId },
      },
      location: { state: { surveyCreated = false } = {} },
      history,
      surveyTitle,
      ...ownProps
    },
  }) => {
    const { createdAt, nodeId, housingCompany, adminId } = get(
      data,
      'allSurveys.nodes[0]',
      {},
    );

    return {
      ...ownProps,
      data: {
        ...data,
        error: !data.loading && !housingCompany ? 'Survey not found' : null,
      },
      surveyCreated,
      createdAt,
      nodeId,
      surveyId,
      surveyLink: `${baseUrl}/${surveyId}`,
      resultsLink: `${baseUrl}/results/${adminId}`,
      surveyTitle: surveyTitle
        ? surveyTitle.replace('$housingCompany', housingCompany)
        : '',
    };
  },
});

const handleCreateAnswer = ({
  createAnswerMutate,
  history,
  surveyId,
}) => async (answers, comment) => {
  try {
    await createAnswerMutate({
      variables: {
        answer: {
          surveyId,
          answerData: JSON.stringify(answers),
          comment,
        },
      },
    });
    history.push('/thanks');
  } catch (err) {
    console.error(err); // eslint-disable-line no-console
    throw err;
  }
};

const createAnswer = graphql(createAnswerMutation, {
  name: 'createAnswerMutate',
  props: ({ createAnswerMutate, ownProps: { history, surveyId } }) => ({
    onCreateAnswer: handleCreateAnswer({
      createAnswerMutate,
      history,
      surveyId,
    }),
  }),
});

const handleCloseSurvey = ({ closeSurveyMutate, nodeId }) => async () => {
  try {
    await closeSurveyMutate({
      variables: { nodeId, patch: { status: 'closed' } },
    });
  } catch (err) {
    console.error(err); // eslint-disable-line no-console
    throw err;
  }
};

const closeSurvey = graphql(updateSurveyMutation, {
  name: 'closeSurveyMutate',
  props: ({ closeSurveyMutate, ownProps: { nodeId } }) => ({
    onCloseSurvey: handleCloseSurvey({
      closeSurveyMutate,
      nodeId,
    }),
  }),
});

export default compose(
  withPrismicMasterRef,
  withPrismicDocument('survey_page'),
  mapProps(({ surveyQuestions = [], surveyOptions = [], ...rest }) => ({
    ...rest,
    surveyQuestions: mapQuestionsWithOptions(surveyQuestions, surveyOptions),
  })),
  whenError(SurveyErrorPage),
  whenLoading(AbsoluteLoadingSpinner),
  getSurvey,
  createAnswer,
  closeSurvey,
  whenError(SurveyErrorPage),
  whenLoading(AbsoluteLoadingSpinner),
  branch(({ createdAt, onCloseSurvey }) => {
    if (hasExpired(createdAt, deleteDays)) {
      onCloseSurvey();
      return true;
    }
    return false;
  }, renderComponent(SurveyErrorPage)),
  whenError(SurveyErrorPage),
  whenLoading(AbsoluteLoadingSpinner),
)(SurveyPage);

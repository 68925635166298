// import 'core-js/modules/es6.symbol';
// import 'core-js/modules/es6.array.find-index';
// import 'core-js/modules/es6.number.is-nan';
// import 'core-js/modules/es6.typed.uint8-clamped-array';
// import 'core-js/modules/es6.weak-map';
// import 'core-js/modules/es7.object.entries';
// import 'custom-event-polyfill';

import 'react-app-polyfill/ie11';
import 'core-js/modules/es6.array.find';
import 'core-js/modules/es6.object.keys';
import 'core-js/modules/es6.string.includes';
import 'core-js/modules/es7.array.includes';

import { createElement } from 'react';
import { render } from 'react-dom';
import './index.css';
// import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import withStore from './store';
import App from './containers/App';

render(createElement(withStore(App)), document.getElementById('root'));

import React, { Fragment } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { withRouter } from 'react-router';
import { Helmet } from 'react-helmet';
import LandingPage from './LandingPage';
import SurveyPage from './SurveyPage';
import ThanksPage from './ThanksPage';
import ResultsPage from './ResultsPage';

const App = () => (
  <Fragment>
    <Helmet
      titleTemplate="%s - Helen Lataustarvekysely"
      defaultTitle="Helen Lataustarvekysely"
    />
    <Switch>
      <Route exact path={'/'} component={LandingPage} />
      <Route exact path={'/survey/:surveyId'} component={SurveyPage} />
      <Route exact path={'/thanks'} component={ThanksPage} />
      <Route exact path={'/results/:surveyId'} component={ResultsPage} />
      <Route exact path={'/:surveyId'} component={SurveyPage} />
      <Route render={() => <Redirect to="/" />} />
    </Switch>
  </Fragment>
);

export default withRouter(App);

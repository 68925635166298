import React, { PureComponent } from 'react';
import { arrayOf, bool, shape, string } from 'prop-types';
import styled from 'styled-components';
import TitleSection from './TitleSection';
import LinksSection from './LinksSection';
import RadioGroup from '../RadioGroup';
import Button from '../Button';
import PageWrapper from '../PageWrapper';
import TextArea from '../TextArea';
import {
  fontSizeH4,
  frameWidth,
  contentWidth,
  contentWidthLarge,
  typeColor,
} from '../../constants/styles';
import Card from '../Card';

const PropTypes = {
  surveyCreated: bool.isRequired,
  surveyTitle: string.isRequired,
  surveyQuestions: arrayOf(
    shape({
      label: string.isRequired,
      options: arrayOf(
        shape({
          label: string.isRequired,
          highlight: string.isRequired,
        }),
      ),
    }),
  ),
  surveyErrorText: string.isRequired,
  surveySubmitText: string.isRequired,
  surveyCommentLabel: string.isRequired,
  surveyCommentPlaceholder: string.isRequired,
};

const DefaultProps = {
  surveyQuestions: [],
};

const Frame = styled.div`
  text-align: center;
  max-width: ${frameWidth};
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 8.5rem;
`;

const Survey = styled.div`
  margin: 4rem auto 0 auto;
  max-width: ${contentWidth};
  padding: 0 0.75rem;

  label {
    color: ${typeColor};
    font-size: ${fontSizeH4};
    font-weight: 700;
    letter-spacing: -1px;
    line-height: normal;
    margin-bottom: 1rem;
    text-align: left;
    text-transform: uppercase;
  }
}
`;

const Questions = styled.div`
  margin-bottom: 3rem;
`;

const SaveButton = styled(Button)`
  margin-top: 3.5rem;
  margin-bottom: 4.5rem;
`;

class SurveyPage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      questions: props.surveyQuestions,
      answers: {},
      comment: null,
    };
  }

  componentWillReceiveProps(newProps) {
    this.setState(prevState => ({
      ...prevState,
      questions: newProps.surveyQuestions,
    }));
  }

  onAnswerChange = (key, value) => {
    this.setState(prevState => ({
      answers: {
        ...prevState.answers,
        [key]: value,
      },
      comment: prevState.comment,
    }));
  };

  onCommentChange = comment => {
    this.setState(prevState => ({
      ...prevState,
      comment,
    }));
  };

  onSubmit = async () => {
    const { surveyErrorText } = this.props;
    const { answers, comment, questions } = this.state;

    const questionsWithErrors = questions.map(question => ({
      ...question,
      error: !answers[question.key] ? surveyErrorText : null,
    }));

    const errors = questionsWithErrors.filter(question => question.error);

    if (errors.length > 0) {
      this.setState({ questions: questionsWithErrors });
    } else if (Object.keys(answers).length) {
      await this.props.onCreateAnswer(answers, comment);
    }
  };

  render() {
    const {
      surveyCreated,
      surveySubmitText,
      surveyCommentLabel,
      surveyCommentPlaceholder,
      ...props
    } = this.props;
    const { questions } = this.state;
    return (
      <Frame>
        <TitleSection surveyCreated={surveyCreated} {...props} />
        <Card maxWidth={contentWidthLarge}>
          <Survey>
            <Questions>
              {questions.map(({ key, options, ...rest }, index) => (
                <RadioGroup
                  key={key}
                  id={key}
                  onChange={value => {
                    this.onAnswerChange(key, value);
                  }}
                  options={options.map(option => option.label)}
                  index={index + 1}
                  {...rest}
                />
              ))}
            </Questions>
            <TextArea
              label={`${questions.length + 1}. ${surveyCommentLabel}`}
              placeholder={surveyCommentPlaceholder}
              onChange={value => {
                this.onCommentChange(value);
              }}
            />
            <SaveButton onClick={this.onSubmit}>{surveySubmitText}</SaveButton>
          </Survey>
        </Card>
        {surveyCreated ? <LinksSection {...props} /> : null}
      </Frame>
    );
  }
}

SurveyPage.propTypes = PropTypes;
SurveyPage.defaultProps = DefaultProps;

export default props => (
  <PageWrapper>
    <SurveyPage {...props} />
  </PageWrapper>
);

/**
 * Font sizes
 */
export const fontSizeH1 = '2.625rem'; // 42px
export const fontSizeH2 = '2.25rem'; // 36px
export const fontSizeH3 = '2rem'; // 32px
export const fontSizeH4 = '1.5rem'; // 24px
export const fontSizeH5 = '1.375rem'; // 22px
export const fontSizeH6 = '1.125rem'; // 18px

export const fontSizeIngressNormal = '1.75rem'; // 28px
export const fontSizeIngressSmall = '1.25rem'; // 20px

export const fontSizeNormal = '1rem'; // 16px
export const fontSizeSmall = '0.9375rem'; // 15px
export const fontSizeSmaller = '0.75rem'; // 12px
export const fontSizeTiny = '0.6875rem'; // 11px

export const frameWidthLarge = '1180px';
export const frameWidth = '1170px';

export const contentWidthLarge = '970px';
export const contentWidth = '770px';

/**
 * Colors
 */
export const white = '#FFF';
export const grayLight = '#E9E9E9';
export const grayDark = '#636C72';
export const greenLight = '#86B4AE';
export const black = '#4A4A4A';
export const violet = '#8C6EC8';

export const typeColor = '#393939';
export const typeColorLight = '#737576';
export const typeColorLighter = '#CECECE';
export const appBackgroundColor = '#F6F6F6';
export const brandColor = '#FF419A';
export const dividerColor = '#D8D8D8';
export const brandColorHover = '#C03073';
export const brandDangerColor = '#EE2645';
export const brandDangerColorHover = '#c10f2b';
export const disabledColor = '#F2F2F2';
export const disabledTypeColor = '#CFCFCF';

// Component
export const ComponentRadius = '0px';
export const ComponentBoxShadow = 'rgba(0, 0, 0, 0.1) 0px 2px 6px 0px';

export const inputThemes = {
  light: {
    inputColor: typeColor,
    labelColor: typeColor,
    placeholderColor: typeColorLighter,
    backgroundColor: white,
    backgroundColorDisabled: '#f0f0f0',
    backgroundColorFocus: white,
    focusBorderColor: '#4A90E2',
    borderColor: typeColor,
    errorMessageBackgroundColor: 'transparent',
    errorMessageTextColor: brandDangerColor,
  },
};

export const buttonThemes = {
  primary: {
    backgroundColor: brandColor,
    hover: brandColorHover,
    color: white,
    disabled: disabledColor,
    disabledColor: disabledTypeColor,
    borderRadius: '0px',
  },
  danger: {
    backgroundColor: brandDangerColor,
    hover: brandDangerColorHover,
    color: white,
    disabled: disabledColor,
    disabledColor: disabledTypeColor,
    borderRadius: '0px',
  },
};

// Fonts
export const fontFamilySansSerif =
  '"Gotham Narrow", "Open Sans", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif';

export const fontFamilyMonoSpace =
  'Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace';

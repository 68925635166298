import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

const getMasterRefQuery = gql`
  query {
    getMasterRef @rest(type: "Refs", path: "") {
      refs @type(name: "Ref") {
        ref
        isMasterRef
      }
    }
  }
`;

const withMasterRef = graphql(getMasterRefQuery, {
  props: ({ data: { getMasterRef: { refs = {} } = {}, ...data } = {} }) => ({
    data,
    masterRef: refs.length > 0 ? refs[0].ref : null,
  }),
});

export default component => withMasterRef(component);

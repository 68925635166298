import React from 'react';
import { shape, string } from 'prop-types';
import styled from 'styled-components';
import Button from '../Button';
import PageWrapper from '../PageWrapper';
import { Heading1, Heading6, Ingress } from '../Heading';
import TeaserList from '../../containers/TeaserList';
import {
  contentWidth,
  fontSizeIngressSmall,
  fontSizeNormal,
} from '../../constants/styles';

const PropTypes = {
  mainTitle: string.isRequired,
  mainSubtitle: string.isRequired,
  bottomSectionImage: shape({
    url: string.isRequired,
  }).isRequired,
  bottomSectionTitle: string.isRequired,
  bottomSectionSubtitle: string.isRequired,
  bottomSectionButtonText: string.isRequired,
  bottomSectionLink: string.isRequired,
};

const Frame = styled.div`
  text-align: center;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2rem;
`;

const TopSection = styled.div`
  text-align: left;
  max-width: ${contentWidth};
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 5rem;
`;

const Title = styled(Heading1)``;

const Subtitle = styled(Ingress)`
  margin-top: 1.5rem;
`;

const BottomSection = styled.div`
  width: 100%;
  height: 500px;
  margin-top: 8rem;
  background-image: url('${props => props.imageUrl}');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  display: flex;
  align-items: center;
`;

const BottomSectionContent = styled.div`
  max-width: ${contentWidth};
  margin-left: auto;
  margin-right: auto;
`;

const BottomSectionTitle = styled(Heading1)`
  color: white;
  font-weight 500;
  text-transform: none;
`;

const BottomSectionSubtitle = styled(Heading6)`
  color: white;
  font-size: ${fontSizeIngressSmall};
  line-height: 1.45;
  margin-top: 1.5rem;

  @media (max-width: 436px) {
    font-size: ${fontSizeNormal};
  }
`;

const BottomSectionButton = styled(Button)`
  margin-top: 3rem;
`;

const ThanksPage = ({
  mainTitle,
  mainSubtitle,
  bottomSectionImage,
  bottomSectionTitle,
  bottomSectionSubtitle,
  bottomSectionButtonText,
  bottomSectionLink,
}) => (
  <Frame>
    <TopSection>
      <Title>{mainTitle}</Title>
      <Subtitle>{mainSubtitle}</Subtitle>
    </TopSection>
    <TeaserList />
    <BottomSection imageUrl={bottomSectionImage.url}>
      <BottomSectionContent>
        <BottomSectionTitle>{bottomSectionTitle}</BottomSectionTitle>
        <BottomSectionSubtitle>{bottomSectionSubtitle}</BottomSectionSubtitle>
        <BottomSectionButton protocolLink href={bottomSectionLink}>
          {bottomSectionButtonText}
        </BottomSectionButton>
      </BottomSectionContent>
    </BottomSection>
  </Frame>
);

ThanksPage.propTypes = PropTypes;

export default props => (
  <PageWrapper>
    <ThanksPage {...props} />
  </PageWrapper>
);

import React from 'react';
import { string } from 'prop-types';
import styled from 'styled-components';
import {
  brandColor,
  brandColorHover,
  fontSizeNormal,
  fontFamilySansSerif,
  typeColor,
  white,
  ComponentRadius,
} from '../../constants/styles';

const PropTypes = {
  id: string.isRequired,
  label: string.isRequired,
};

const pukeGreen = 'rgb(78, 173, 109)';

const CheckboxContainer = styled.div``;

const CheckboxWithLabel = styled.label`
  margin-bottom: 0rem;
  margin-right: 1rem;
`;

const CheckboxLabel = styled.span`
  a {
    color: ${brandColor};

    &:hover {
      color: ${brandColorHover};
    }
  }
`;

const CheckboxInput = styled.input`
  &:checked,
  &:not(:checked) {
    left: -9999px;
    position: absolute;

    + ${CheckboxLabel} {
      color: ${typeColor};
      cursor: pointer;
      display: inline-block;
      font-family: ${fontFamilySansSerif};
      font-size: ${fontSizeNormal};
      line-height: 1rem;
      margin-left: 0.8rem;
      padding-left: 28px;
      position: relative;
      user-select: none;

      &:before {
        background: ${white};
        border-radius: ${ComponentRadius};
        border: 1px solid ${typeColor};
        content: '';
        height: 13px;
        left: 0;
        position: absolute;
        top: 0;
        width: 13px;
      }

      &:after {
        background: ${pukeGreen};
        border: 2px solid #fff;
        border-right: none;
        border-top: none;
        color: white;
        content: '';
        font-family: ${fontFamilySansSerif};
        font-size: 0.9rem;
        font-weight: 700;
        height: 5px;
        left: 2px;
        position: absolute;
        top: 3px;
        transform: rotate(-45deg);
        width: 9px;
      }
    }
  }

  &:checked + ${CheckboxLabel} {
    &:before {
      background: ${pukeGreen};
      border-width: 0px;
    }

    &:after {
      opacity: 1;
    }
  }

  &:not(:checked) {
    + ${CheckboxLabel} {
      color: ${typeColor};

      &:hover:before {
        background: #eeeeee;
      }

      &:after {
        opacity: 0;
      }
    }
  }
`;

const Checkbox = ({ id, label, ...rest }) => (
  <CheckboxContainer>
    <CheckboxWithLabel htmlFor={id}>
      <CheckboxInput id={id} {...rest} type="checkbox" />
      <CheckboxLabel dangerouslySetInnerHTML={{ __html: label }} />
    </CheckboxWithLabel>
  </CheckboxContainer>
);

Checkbox.propTypes = PropTypes;

export default Checkbox;

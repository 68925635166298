import { ApolloClient } from 'apollo-client';
import { createHttpLink } from 'apollo-link-http';
import { RestLink } from 'apollo-link-rest';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { onError } from 'apollo-link-error';
import { ApolloProvider } from 'react-apollo';

const { REACT_APP_GRAPHQL_ENDPOINT, REACT_APP_PRISMIC_API_URL } = process.env;

const client = () => {
  const httpLink = createHttpLink({
    uri: `${REACT_APP_GRAPHQL_ENDPOINT}`,
  });

  const prismicRestLink = new RestLink({
    uri: REACT_APP_PRISMIC_API_URL,
    headers: {},
  });

  const errorLink = onError(() => {});

  return new ApolloClient({
    link: errorLink.concat(prismicRestLink).concat(httpLink),
    cache: new InMemoryCache(),
  });
};

export { ApolloProvider, client };

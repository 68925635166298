import React, { PureComponent } from 'react';
import {
  arrayOf,
  func,
  number,
  oneOf,
  oneOfType,
  shape,
  string,
} from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';
import { mediaBreakpointDownSm } from 'styled-bootstrap-responsive-breakpoints';

import {
  brandColor,
  dividerColor,
  fontSizeSmall,
  fontSizeH4,
  inputThemes,
  typeColor,
  ComponentRadius,
} from '../../constants/styles';

const PropTypes = {
  id: oneOfType([string, number]),
  label: string.isRequired,
  options: arrayOf(string).isRequired,
  mode: oneOf(['single', 'multi']),
  onChange: func.isRequired,
  index: number.isRequired,
  error: string,
  theme: shape({}),
};

const DefaultProps = {
  id: null,
  mode: 'single',
  error: null,
  theme: inputThemes.light,
};

const Container = styled.div`
  border-bottom: 1px solid ${dividerColor};
  padding-bottom: 3.5rem;
  padding-top: 3rem;
`;

const Label = styled.label`
  color: ${typeColor};
  font-size: ${fontSizeH4};
  font-weight: 700;
  margin-bottom: 1rem;
  margin-left: 2px;
  text-align: left;
  text-transform: uppercase;
  user-select: none;
  width: 100%;
`;

const RadioButtons = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  margin-top: 0.5rem;
`;

const RadioButtonContainer = styled.div`
  flex-basis: 22.5%;
  padding: 0.25rem 0.15rem;

  ${mediaBreakpointDownSm`
    flex-basis: 100%;
  `};
`;

const getRadioButtonColor = ({ hasSelection, isSelected }) => {
  if (!hasSelection) {
    return typeColor;
  }
  return isSelected ? brandColor : '#a7a7a7';
};

const RadioButton = styled.div`
  align-items: center;
  background-color: white;
  border-color: ${props => getRadioButtonColor(props)};
  border-radius: ${ComponentRadius};
  border-style: solid;
  border-width: ${props => (props.isSelected ? '2px' : '1px')};
  color: ${props => getRadioButtonColor(props)};
  cursor: pointer;
  display: flex;
  flex-basis: 25%;
  font-size: ${fontSizeSmall};
  font-weight: 400;
  height: 100%;
  justify-content: center;
  line-height: 0.875rem;
  margin-bottom: ${props => (props.isSelected ? '-2px' : '0px')};
  padding: 1rem 1.4rem;
  width: 100%;

  ${mediaBreakpointDownSm`
    flex-basis: 100%;
  `};
`;

const RadioButtonTitle = styled.div`
  padding: 0.7rem 0;
  user-select: none;
`;

const Error = styled.div`
  color: ${props => props.theme.errorMessageTextColor};
  font-size: ${fontSizeSmall};
  font-weight: 500;
  padding: 0rem 0rem 0.75rem 0.25rem
  text-align: left;
`;

class RadioGroup extends PureComponent {
  state = {
    selectedOptions: [],
  };

  onButtonClick = option => {
    const { mode } = this.props;
    let { selectedOptions } = this.state;

    if (mode === 'single') {
      selectedOptions = [];
    }

    if (selectedOptions.includes(option)) {
      selectedOptions = selectedOptions.filter(o => o !== option);
    } else {
      selectedOptions = selectedOptions.concat(option);
    }

    this.setState({ selectedOptions });

    this.props.onChange(
      mode === 'single' && selectedOptions.length === 1
        ? selectedOptions[0]
        : selectedOptions,
    );
  };

  render() {
    const { id, label, error, theme, options, index } = this.props;
    const { selectedOptions } = this.state;
    const hasSelection = selectedOptions.length > 0;

    return (
      <ThemeProvider theme={theme}>
        <Container>
          {label && (
            <Label error={error} htmlFor={id}>
              {`${index}. ${label}`}
            </Label>
          )}
          {error && <Error>{error}</Error>}
          <RadioButtons id={id}>
            {options.map(option => (
              <RadioButtonContainer key={option}>
                <RadioButton
                  hasSelection={hasSelection}
                  isSelected={selectedOptions.includes(option)}
                  onClick={() => {
                    this.onButtonClick(option);
                  }}
                >
                  <RadioButtonTitle>{option}</RadioButtonTitle>
                </RadioButton>
              </RadioButtonContainer>
            ))}
          </RadioButtons>
        </Container>
      </ThemeProvider>
    );
  }
}

RadioGroup.propTypes = PropTypes;
RadioGroup.defaultProps = DefaultProps;

export default RadioGroup;

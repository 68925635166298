import React from 'react';
import { string } from 'prop-types';
import styled from 'styled-components';
import { Heading1, Ingress } from '../../Heading';
import { contentWidth } from '../../../constants/styles';

const PropTypes = {
  mainTitle: string.isRequired,
  mainSubtitle: string.isRequired,
};

const DefaultProps = {};

const Container = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: ${contentWidth};
`;

const Title = styled(Heading1)`
  line-height: 1.15;
  text-align: left;
  margin-top: 2rem;
`;

const Subtitle = styled(Ingress)`
  margin-left: auto;
  margin-right: auto;
  margin-top: 1.5rem;
  text-align: left;
`;

const TopSection = ({ mainTitle, mainSubtitle }) => (
  <Container>
    <Title>{mainTitle}</Title>
    <Subtitle>{mainSubtitle}</Subtitle>
  </Container>
);

TopSection.propTypes = PropTypes;
TopSection.defaultProps = DefaultProps;

export default TopSection;

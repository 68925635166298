import React from 'react';
import styled from 'styled-components';
import { array, element, oneOfType, string } from 'prop-types';
import { ComponentBoxShadow, frameWidth } from '../../constants/styles';

const PropTypes = {
  children: oneOfType([element, string, array]).isRequired,
  maxWidth: string,
};

const DefaultProps = {
  maxWidth: frameWidth,
};

const StyledDiv = styled.div`
  max-width: ${props => props.maxWidth};
  margin-left: auto;
  margin-right: auto;
  background: white;
  box-shadow: ${ComponentBoxShadow};
`;

const Card = ({ maxWidth, children }) => (
  <StyledDiv maxWidth={maxWidth}>{children}</StyledDiv>
);

Card.propTypes = PropTypes;
Card.defaultProps = DefaultProps;

export default Card;
